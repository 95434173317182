// import Login from "../components/Login.vue"
// import AppList from "../components/AppList.vue"
import Layout from "../components/Layout.vue"
// import User from "../components/User"
// import Manufacturer from "../components/Manufacturer"
// import ManufacturerApp from "../components/ManufacturerAppList"
// import Spider from "../components/Spider"
// import Developer from "../components/Developer"
// import Stat from "../components/Stat"
import { createRouter, createWebHashHistory } from "vue-router"
import { getToken, getUserId } from "../utils/auth"
// import Notfound from "../pages/404.vue"
import { getRole } from "../utils/permission"
import enumValue from "../utils/enum"
import { messageWarning } from "../utils/message"


// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
    {
        path: '/app',
        component: Layout,
        children: [
            {
                path: "list",
                name: "appList",
                component: ()=>import("../components/AppList.vue"),
                meta: { requiresAuth: true, requireAdmin: false }
            }
        ]
    },
    {
        path: '/user',
        component: Layout,
        children: [
            {
                path: "list",
                name: "userList",
                component: ()=>import("../components/User"),
                meta: { requiresAuth: true, requireAdmin: true }
            }
        ]
    },
    {
        path: '/manufacturer',
        component: Layout,
        children: [
            {
                path: "list",
                name: "manufacturerList",
                component: ()=>import("../components/Manufacturer") ,
                meta: { requiresAuth: true, requireAdmin: false },
            },
            {
                path: "appList",
                name: "manufacturerAppList",
                component: ()=>import("../components/ManufacturerAppList"),
                meta: { requiresAuth: true, requireAdmin: false }
            },
        ]
    },
    {
        path: '/developer',
        component: Layout,
        children: [
            {
                path: "list",
                name: "developerList",
                component: ()=>import("../components/Developer"),
                meta: { requiresAuth: true, requireAdmin: false },
            },
        ],
    },
    {
        path: "/spider",
        component: Layout,
        children: [
            {
                path: "list",
                name: "spiderList",
                component: ()=>import("../components/Spider"),
                meta: { requiresAuth: true, requireAdmin: false }
            },
        ],
    },
    {
        path: "/stat",
        component: Layout,
        children: [
            {
                path: "info",
                name: "statInfo",
                component: ()=>import("../components/Stat"),
                meta: { requiresAuth: true, requireAdmin: false }
            },
        ],
    },
    { path: '/user/login', name: "login", component: ()=>import("../components/Login.vue"), requireAdmin: false },
    { path: '/user/register', name: "register", component: ()=>import("../components/Register.vue"), requireAdmin: false },
    { path: '/', redirect: "/app/list", requireAdmin: false },
    {
        path: '/:pathMatch(.*)*',
        component: ()=>import("../pages/404.vue"),
        meta: {
            title: '找不到页面'
        }
    }
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to) => {
    const token = getToken();
    console.log(token);
    // 验真是否登陆
    if (to.meta.requiresAuth && token == "") {
        // 此路由需要授权，请检查是否已登录
        // 如果没有，则重定向到登录页面
        return {
            path: '/user/login',
            // 保存我们所在的位置，以便以后再来
            query: { redirect: to.fullPath },
        }
    }
    // 验证权限
    if (to.meta.requireAdmin) {
        const role = getRole(getUserId());
        if (role != enumValue.permissionAdmin) {
            messageWarning("权限不足，不能访问该页面,请登陆对应账户")
            return {
                path: '/user/login',
                // 保存我们所在的位置，以便以后再来
                query: { redirect: to.fullPath },
            }
        }

    }
})

export default router;